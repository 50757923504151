@charset "UTF-8";

body {
    background-color: white;
}

.btn-warning {
    background-color: #8b7043 !important;
    color: white !important;
    padding: 14px 30px;
}

.boxes {
    width: 100%;
    margin-left: 1px !important;
}

.box {
    background-color: rgba(169, 142, 95, 0.5);
    min-width: 100px;
    height: 30px;
    padding-bottom: 0 !important;
    padding-top: 15px;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding-left: 12px;
    padding-right: 12px;
}

.boxes .box {
    display: inline-block;
    height: 60px;
    border: 1px solid white;
    vertical-align: middle;
}

.driver_check {
    text-align: left;
    padding-left: 38px;
}

.return_loc_align {
    text-align: left !important;
}

.button_align {
    text-align: right;
}

.line {
    border-top: 1px solid rgba(124, 176, 233, 0.3);
}

@media only screen and (max-width: 767px) {
    .line {
        border-top: 1px solid rgba(124, 176, 233, 0);
    }

    .left_marg {
        margin-left: 5px !important;
    }
}

.icon_size {
    color: #ffffff;
    font-size: 1rem !important;
    font-weight: 600;
    text-align: center;
}

.icon_show {
    display: inline-block;
}

@media only screen and (max-width: 522px) {
    .icon_size {
        color: white !important;
        font-size: 14px !important;
    }

    .icon_show {
        display: none;
    }

    .box {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media only screen and (max-width: 700px) {
}

.search_box {
    background-color: rgba(0, 0, 0, 0.62);
    color: white;
    border-radius: 0 0 16px 16px;
    padding: 28px 28px 12px;
}

.card-body {
    background-color: transparent;
}

.more_box {
    width: 100%;
    padding: 0px 7px;
    border: none;
}

.more_control {
    width: 100%;
}

.nice-select.open .list {
    max-height: 200px !important;
    overflow: scroll !important;
    width: 100%;
}

.more_but {
    text-align: center !important;
}

.row_mar {
}

.left_text {
    text-align: left;
}

.box_shadow {
    padding: 5px;
    background-color: rgba;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.tab_width {
    width: 400px;
}

.text_border_color {
    background-color: white;
}

.link_but {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 25px;
    position: relative;
}

    .link_but::after {
        content: "";
        position: absolute;
        bottom: 15px;
        left: 14px;
        border-right: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        display: block;
        width: 9px;
        height: 9px;
    }

.smalldesc {
    max-height: 252px;
    overflow: hidden;
    transition: all 1s ease;
}

    .smalldesc.expand {
        max-height: var(--originalHeight);
    }

#expend {
    display: none;
}

    #expend + .smalldesc {
        max-height: 52px;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    #expend:checked + .smalldesc {
        max-height: 250px;
    }

.label1 {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

label:hover {
    text-decoration: none;
}

.nav-pills .nav-link {
    border-radius: 0%;
    padding: 0 0 4px;
    margin: 0 42px 0 0;
}

    .nav-pills .nav-link.active {
        background-color: transparent;
        border-bottom: 1px solid #bd9d67;
        color: #d7c5a8;
    }

.listing_color {
    color: #c4a672;
}

.discover_top {
    margin-top: 130px;
}

.ps-home-addons {
    position: relative;
    text-align: center;
    background-color: rgba(250, 250, 250, 0);
}

.home-addons-column {
    width: 20%;
    text-align: center;
    position: relative;
    display: inline-block;
    padding: 2% 2%;
}

.home-addons-column-arrow {
    position: relative;
}

    .home-addons-column-arrow::after {
        position: absolute;
        content: "➤";
        right: -19%;
        top: 29%;
        width: 80px;
        height: 1px;
        font-family: "JannaLT";
        background-color: #a98e5f;
        text-align: right;
        line-height: 2px;
        color: #a98e5f;
    }

.home-addon-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #a98e5f;
    text-align: center;
    display: inline-block;
}

    .home-addon-image i {
        color: black;
        line-height: 100px;
    }

.home-addon-details {
    margin-top: 20px;
}

.home-addon-title {
    font-size: 14px;
    text-transform: uppercase;
    color: #565656;
    font-family: "JannaLT", sans-serif;
    font-weight: 600;
}

    .home-addon-title span {
        color: #a98e5f;
        font-family: "JannaLT", sans-serif;
        font-weight: 700;
    }

.home-addon-description {
    margin-top: 10px;
    color: #606060;
    font-size: 13px;
}

@media only screen and (max-width: 1199px) {
    .home-addons-column-arrow::after {
        width: 65px;
    }
}

@media only screen and (max-width: 991px) {
    .home-addons-column-arrow::after {
        display: none;
    }

    .home-addons-column {
        width: 49%;
    }
}

@media only screen and (max-width: 470px) {
    .btn_mobile {
        height: 70px
    }

}

@media only screen and (max-width: 400px) {
    .home-addons-column {
        width: 100%;
    }
}

.ps-home-aboutus {
    background-color: rgba(250, 250, 250, 0);
}

.ic_siz1 {
    font-size: 45px !important;
}

.intro {
    text-align: center;
}

.intro-text {
    font-size: 22px;
    color: #81807f;
    font-family: "JannaLT", sans-serif;
}

.welcome_margin_top {
    margin-top: 40px;
}

    .welcome_margin_top h2 {
        font-weight: 700;
        font-size: 40px;
        padding-bottom: 35px;
    }

    .welcome_margin_top .welcome_img img {
        margin-left: 100px;
        margin-top: 122px;
    }
/*
.list_div {
    float: left;
    margin-top: 19px;
}*/

.ic_float {
    float: left !important;
    color: #81807f;
}

.list_welcome {
    float: left;
    font-size: 20px;
    padding-left: 8px;
    padding-top: 0px;
    color: #81807f;
}

.home_bg {
    /*background-image: url("/assets/img/home_bg.jpg");*/
    background-repeat: repeat;
}

.blog .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -40px;
}

    .blog .carousel-indicators li {
        background: #a3a3a3;
        border-radius: 50%;
        width: 8px;
        height: 8px;
    }

    .blog .carousel-indicators .active {
        background: #707070;
    }

.hide_control {
    display: none;
}

.smile_icon {
    padding-top: 24px;
    padding-left: 2px;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
    background-color: #bd9d67;
    text-align: center;
}

    .nav-tabs .nav-item .active {
        margin-bottom: -1px;
        background-color: #ae8f47 !important;
    }

.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}

.first_tab {
    border-radius: 16px 0 0 0 !important;
    border-right: 1px solid white;
}

    .first_tab .active {
        border-radius: 16px 0 0 0 !important;
        border-right: 1px solid white;
    }

.last_tab {
    border-radius: 0 16px 0 0 !important;
    border-left: 1px solid white;
}

    .last_tab .active {
        border-radius: 0 16px 0 0 !important;
        border-left: 1px solid white;
    }

.nav-tabs a {
    padding-top: 10px;
}

@media only screen and (max-width: 390px) {


    .nav-tabs .nav-item .active {
        height: 50px !important;
    }

    .nav-tabs li {
        width: 110px !important;
    }

    .nav-item {
        padding-left: 0px !important;
    }
}

.nav-tabs .nav-link {
    color: white !important;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.title_img {
    background-color: #a98e5f;
}

.search_box a {
    color: white;
    text-decoration: none;
    transition: 0.5s;
}

.link_but a {
    color: white !important;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 8px !important;
}

.form-control2 {
    border-radius: 5px;
    opacity: 1;
}

.nice-select {
    background-color: transparent !important;
}

.form-control {
    
    height: 42px;
    border-radius: 0;
    box-shadow: unset !important;
    padding: 0 0 0 15px;
    border: 1px solid #7b7b7b;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    transition: 0.5s;
}

@media only screen and (max-width: 767px) {
    .icon_size {
        font-size: 14px !important;
    }

    ul.sub-tabs .nav-link {
        margin: 0 20px 0 0 !important;
    }

        ul.sub-tabs .nav-link :last-child {
            margin: 0 0 0 0 !important;
        }

    .main-banner-content .nav-item a {
        padding: 15px 2px;
    }

    .discover_top {
        margin-top: 43px;
    }

    .welcome_margin_top {
        font-size: 33px;
    }
}

.main-banner-img {
    position: relative;
    z-index: 1;
    padding-top: 300px;
    padding-bottom: 300px;
    background-color: white;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 832px;
}

.main-banner-img {
    /*background-image: linear-gradient(to left bottom, #0000009c, #3c3a3678), url('home-banner.jpg');*/
    /*border-radius: 0 0 2rem 2rem;*/
}

.imgWrapper img {
    vertical-align: middle;
    border-style: none;
}

.textWrapper h2 {
    font-size: 40px;
    margin-bottom: 45px;
}

.hekaya-container {
    width: 85%;
    margin: 0 auto;
}

.hekaya-banner-Hekayat {
    border-radius: 2rem;
    /*background-image: linear-gradient(to left bottom, #a7373700, #20012200), url('self-drive-bg.jpg');*/
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 40vh;
    position: relative;
}

.right {
    width: 50%;
    float: left;
    box-sizing: border-box;
}

.left {
    width: 50%;
    float: right;
}

.boxshadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.round-radius {
    border-radius: 2rem;
}

.single-team-member {
    display: flex;
    flex-wrap: wrap;
    align-items: center !important;
    margin-bottom: 30px;
    border: 1px solid #eee;
}

.fs-19px {
    font-size: 19px;
}

.row-margin-top {
    margin-top: 120px !important;
}

.hekaya-title {
    color: #333;
}

.title-color {
    color: #ae8f47;
}

/*p {
    color: #555;
}*/

p {
    margin-bottom: 15px;
    font-size: 18px;
}

.hekaya-button {
    background-color: #ae8f47;
    border-color: #ae8f47;
    color: white;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
}

.car-img {
    height: 80%;
}

.boxrow {
    margin-left: auto;
    margin-right: auto;
    width: 625px;
    background-color: red;
}

.boxicon {
    height: 100px;
    width: 100px;
    background-color: black;
    float: left;
    margin-right: 25px;
}

.selector {
    position: absolute;
    left: 44%;
    width: 300px;
    height: 300px;
    margin-top: 136px;
    margin-left: -70px;
}

    .selector, .selector button {
        font-family: "JannaLT", sans-serif;
        font-weight: 300;
    }

        .selector button {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 10px;
            border-radius: 50%;
            border: 0;
            color: white;
            font-size: 20px;
            cursor: pointer;
            border: 0.5px solid #8b7043;
            transition: all 0.1s;
            background-color: transparent;
        }

            .selector button:focus {
                outline: none;
            }

        .selector ul {
            position: absolute;
            list-style: none;
            padding: 0;
            margin: 0;
            top: -20px;
            right: -20px;
            bottom: -20px;
            left: -20px;
        }

        .selector li {
            position: absolute;
            width: 0;
            height: 100%;
            margin: 0 50%;
            -webkit-transform: rotate(-360deg);
            transition: all 0.8s ease-in-out;
        }

            .selector li input {
                display: none;
            }

                .selector li input + label {
                    position: absolute;
                    left: 50%;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                    line-height: 1px;
                    margin-left: 0;
                    background: #fff;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 1px;
                    overflow: hidden;
                    cursor: pointer;
                    box-shadow: none;
                    transition: all 0.8s ease-in-out, color 0.1s, background 0.1s;
                }

                .selector li input:checked + label {
                    background: #8b7043;
                    color: white;
                }

        .selector.open li input + label {
            width: 80px;
            height: 80px;
            line-height: 80px;
            margin-left: -40px;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
            font-size: 18px;
            background-color: #8b7043;
            color: white;
        }

.mt-10 {
    margin-top: 25px;
}


p {
    color: #555;
}

.main-text {
    font-size: 49px;
    font-weight: 800;
}

.color-hekayat {
    color: #8b7043;
}

body {
    font-family: "JannaLT";
}

.bold-text {
    font-weight: 600;
}

.col-padding {
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 2% !important;
}

.self-camp-gear {
    color: #333;
    text-align: left;
    margin-top: 20px;
}

.self-camp-gear-p {
    color: #555;
    text-align: left;
}

.img-3 {
    position: absolute;
    margin-top: 54%;
    margin-left: -33%;
    border: 11px solid white;
    height: 314px;
    width: 429px;
    z-index: 1;
}

@media only screen and (max-width: 993px) {
    .img-3 {
        display: none;
    }
}

.top-row-margin {
    margin-top: 40px;
    margin-bottom: 40px;
}

.center-content {
    flex-direction: column;
    text-align: right;
    margin-bottom: 14px !important;
    color: white;
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 25px;
}

.text-right {
    text-align: right;
}

.col-right-padding {
    padding: 1%;
}

.guidance {
    color: white;
}

.over-lay {
    background-color: rgba(0, 0, 0, 0.5);
}

.margin-overlay {
    margin-right: 2%;
}

.point-align {
    text-align: right;
}

.point-align2 {
    text-align: left;
}

@media only screen and (min-width: 768px) {
    .car-5 {
        min-height: 475px;
    }
}

@media only screen and (max-width: 767px) {
    .point-align {
        text-align: center;
    }

    .point-align2 {
        text-align: center;
    }
}

.point-align p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 50px;
}

.point-align2 p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 50px;
}

.bot-margin {
    margin-bottom: 100px;
}

.top-margin {
    margin-top: 145px;
}

.img-4 {
    margin-top: -135px;
    overflow: hidden;
}

@media only screen and (max-width: 992px) {
    .img-4 {
        width: 100%;
        margin-top: -75px;
        height: 86%;
        margin-top: 7%;
        margin-left: 7%;
    }
}

@media only screen and (min-width: 768px) {
    .col-pad2 {
        padding-left: 0px !important;
    }
}

@media only screen and (max-width: 1150px) {
    .img-3 {
        height: 300px;
    }
}

.camp-gear-img {
    position: relative;
    z-index: 99;
}

.top-margin2 {
    margin-top: 35px;
    padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
    .self-camp-padding {
        padding-top: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .col-padding {
        padding-bottom: 80px;
    }
}

@media only screen and (max-width: 763px) {
    .col-padding {
        padding-bottom: 15px;
        padding-top: 0px;
    }
}

@media only screen and (max-width: 350px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 35vw;
        right: 6vw;
        width: 280px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 38%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
    }

    .main-text {
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }

    .self-Drive {
        color: white;
        text-align: center;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: center;
        margin-top: 20px;
    }

    .self-camp-p {
        color: white;
        font-weight: normal;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 90%;
        height: 350px;
        border: 11px solid white;
        margin-left: 23px;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 200px;
        height: 35vh;
        min-height: 188px;
        background-color: white;
        margin-left: 94px;
        position: absolute;
        margin-top: 65px;
        padding-top: 25px;
        z-index: 99;
    }

    .margin {
        margin-left: 0px;
        font-size: 22px;
    }

    .margin-p {
        margin-left: 0px;
        font-size: 14px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
        height: 138px;
        width: 100%;
    }

    .banner-text {
        margin-top: 4%;
        font-weight: 600;
        font-size: 20px;
    }

    .txt-bottom {
        margin-top: 24px;
    }
}

@media only screen and (min-width: 375px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 35vw;
        width: 348px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 38%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: center;
    }

    .main-text {
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }

    .self-Drive {
        color: white;
        text-align: center;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: center;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: center;
        margin-top: 20px;
    }

    .self-camp-p {
        color: white;
        font-weight: normal;
        text-align: center;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 90%;
        height: 350px;
        border: 11px solid white;
        margin-left: 23px;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 200px;
        height: 35vh;
        min-height: 188px;
        background-color: white;
        margin-left: 157px;
        position: absolute;
        margin-top: 41px;
        padding-top: 25px;
        z-index: 99;
    }

    .margin {
        margin-left: 0px;
        font-size: 26px;
    }

    .margin-p {
        margin-left: 0px;
        font-size: 14px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
        height: 183px;
        width: 100%;
    }

    .banner-text {
        margin-top: 4%;
        font-weight: 600;
        font-size: 30px;
    }
}

@media only screen and (min-width: 425px) {

  


    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 35vw;
        right: 7vw;
        width: 348px;
       
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 38%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: center;
    }

    .main-text {
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }

    .self-Drive {
        color: white;
        text-align: center;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: center;
        margin-top: 20px;
    }

    .self-camp-p {
        color: white;
        font-weight: normal;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 90%;
        height: 350px;
        border: 11px solid white;
        margin-left: 23px;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 200px;
        height: 35vh;
        min-height: 188px;
        background-color: white;
        margin-left: 157px;
        position: absolute;
        margin-top: 41px;
        padding-top: 25px;
        z-index: 99;
    }

    .margin {
        margin-left: 0px;
        font-size: 26px;
    }

    .margin-p {
        margin-left: 0px;
        font-size: 14px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
        height: 183px;
        width: 100%;
    }

    .banner-text {
        margin-top: 4%;
        font-weight: 600;
        font-size: 30px;
    }
}

@media only screen and (min-width: 600px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 35vw;
        right: 7vw;
        width: 348px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 38%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: center;
    }

    .main-text {
        font-size: 30px;
        font-weight: 800;
        text-align: center;
    }

    .self-Drive {
        color: white;
        text-align: center;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: center;
        margin-top: 20px;
    }

    .self-camp-p {
        color: white;
        font-weight: normal;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 90%;
        height: 350px;
        border: 11px solid white;
        margin-left: 23px;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 200px;
        height: 35vh;
        min-height: 188px;
        background-color: white;
        margin-left: 157px;
        position: absolute;
        margin-top: 41px;
        padding-top: 25px;
        z-index: 99;
    }

    .margin {
        margin-left: 0px;
        font-size: 26px;
    }

    .margin-p {
        margin-left: 0px;
        font-size: 14px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
        height: 183px;
        width: 100%;
    }

    .banner-text {
        margin-top: 4%;
        font-weight: 600;
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 35vw;
        right: 7vw;
        width: 348px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 22%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: initial;
    }

    .main-text {
        font-size: 40px;
        font-weight: 800;
    }

    .self-Drive {
        color: white;
        text-align: initial;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: initial;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-camp-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 100%;
        height: 350px;
        border: 11px solid white;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 379px;
        height: 35vh;
        min-height: 274px;
        background-color: white;
        margin-left: 50px;
        position: absolute;
        margin-top: 98px;
        padding-top: 62px;
        padding-left: 52px;
        z-index: 99;
    }

    .margin {
        margin-left: 0px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
    }

    .banner-text {
        margin-top: 35%;
        font-weight: 600;
        font-size: 50px;
    }

    .point-align p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .point-align2 p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .center-point {
        font-size: 14px;
        font-weight: 600;
    }
}

@media only screen and (min-width: 992px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 13vw;
        right: 7vw;
        width: 538px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 22%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: initial;
    }

    .main-text {
        font-size: 46px;
        font-weight: 800;
    }

    .self-Drive {
        color: white;
        text-align: initial;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-camp {
        color: white;
        text-align: initial;
        margin-top: 20px;
        font-size: 26px;
    }

    .self-camp-p {
        color: white;
        text-align: justify;
        font-weight: normal;
        font-size: 18px;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 100%;
        height: 350px;
        border: 11px solid white;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 80px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 500px;
        height: 35vh;
        min-height: 307px;
        background-color: white;
        margin-left: 50px;
        position: absolute;
        margin-top: 98px;
        padding-top: 62px;
        padding-left: 32px;
        z-index: 99;
    }

    .margin {
        margin-left: 100px;
    }

    .margin-p {
        margin-left: 100px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        background-size: unset;
        background-repeat: no-repeat;
    }

    .banner-text {
        margin-top: 35%;
        font-weight: 600;
        font-size: 50px;
    }

    .point-align p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .point-align2 p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 14px;
    }

    .center-point {
        font-size: 14px;
        font-weight: 600;
    }

    .img-3 {
        margin-top: 49%;
        width: 70%;
        height: 28%;
    }
}

@media only screen and (min-width: 1200px) {
    .main-banner-content-box {
        max-width: 1050px;
        position: absolute;
        padding: 0px;
        top: 13vw;
        right: 7vw;
        width: 538px;
    }

    .main-categories-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        overflow: hidden;
        bottom: -70px;
        max-width: 620px;
        margin-left: auto;
        margin-right: auto;
    }

    .nav-tabs .nav-item img {
        width: 22%;
    }

    .nav-tabs .nav-item small {
        font-size: 12px;
    }

    .small-text {
        font-size: 18px;
        text-align: initial;
    }

    .main-text {
        font-size: 49px;
        font-weight: 800;
        text-align: initial;
    }

    .self-Drive {
        color: white;
        text-align: initial;
        margin-top: 20px;
    }

    .cat-section {
        padding-left: 9%;
    }

    .self-Drive-p {
        color: white;
        text-align: justify;
        font-weight: normal;
    }

    .self-camp {
        color: white;
        text-align: left;
        margin-top: 20px;
        margin-left: 35px;
    }

    .self-camp-p {
        color: white;
        text-align: left;
        font-weight: normal;
        margin-left: 35px;
    }

    .self-camp-padding {
        margin-top: 148px;
    }

    .img1 {
        width: 100%;
        height: 403px;
        border: 11px solid white;
    }

    .hero {
        /*background-image: url('HomePageGuidance.jpg');*/
        background-size: cover;
        background-position: center;
        width: 100wh;
        height: 44vh;
        border-radius: 25px;
    }

    .center-content {
        flex-direction: column;
        text-align: right;
        margin-bottom: 14px !important;
        color: white;
        font-weight: 800;
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        padding-top: 100px;
        padding-bottom: 100px;
        border-radius: 25px;
    }

    .whitebox {
        width: 500px;
        height: 35vh;
        min-height: 400px;
        background-color: white;
        margin-left: 119px;
        position: absolute;
        margin-top: 98px;
        padding-top: 62px;
        padding-left: 32px;
        z-index: 99;
    }

    .margin {
        margin-top: 90px;
        margin-left: 100px;
    }

    .banner-home {
        /*background-image: url('banner.jpg');*/
        height: 600px;
        background-size: unset;
        background-repeat: no-repeat;
    }

    .banner-text {
        margin-top: 35%;
        font-weight: 600;
        font-size: 50px;
        margin-left: 30px;
    }

    .point-align p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .point-align2 p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .center-point {
        font-size: 18px;
        font-weight: 600;
    }

    .img-3 {
        position: absolute;
        margin-top: -40%;
        margin-left: 63%;
        border: 11px solid white;
        height: 314px;
        width: 429px;
        z-index: 1;
    }

    .self-Guidence-p {
        color: white;
        font-weight: normal;
    }
}

/*================================================
About Area CSS
=================================================*/



/*---- Loader ----*/
.app-loader,
.view-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.view-loader {
    display: block;
    padding-top: 160px;
    background: rgba(255, 255, 255, 0.3);
    z-index: 9999;
}

    .view-loader * {
        margin: auto;
    }

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.bg-dark021 {
    background: rgb(0, 0, 0, 0.5) !important;
}
.custom-dialog-container {
    background-color: rgb(0, 0, 0, 0.5) !important;
}

.custom-dialog-container .mat-dialog-container {
    padding: 0;
    /*background-color: rgb(0, 0, 0, 0.5) !important;*/
}

    .custom-dialog-container .mat-dialog-container .mat-dialog-content {
        margin: 0;
        /*background-color: rgb(0, 0, 0, 0.5) !important;*/
    }

.mat-tab-group {
    font-family: "JannaLT", sans-serif;
}



.complete-panel {
    border-radius: 20px;
}
